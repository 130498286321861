import React from 'react';
import TableCell from "@material-ui/core/TableCell";

export default function AdvancedSettings({}:any) {

  return (
    <>
      <>
        <TableCell>
          In development
        </TableCell>
        <TableCell>
          In development
        </TableCell>
      </>
    </>
  )
}


{/*<TextField*/}
{/*  type="number"*/}
{/*  onChange={(e) => updateVideoTimeUrl(e.target.value, setUrlTypeVideoStart, 'start')}*/}
{/*  InputProps={{*/}
{/*    inputProps: {*/}
{/*      min: 0 , max: 30*/}
{/*    }*/}
{/*  }}*/}
{/*  label="Start"*/}
{/*/>*/}
{/*<TextField*/}
{/*  type="number"*/}
{/*  onChange={(e) => updateVideoTimeUrl(e.target.value, setUrlTypeVideoEnd, 'end')}*/}
{/*  InputProps={{*/}
{/*    inputProps: {*/}
{/*      min: 0 , max: 30*/}
{/*    }*/}
{/*  }}*/}
{/*  label="End"*/}
{/*/>*/}
