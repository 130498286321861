import '../scss/nft.scss';
import React, {useState} from 'react';
import * as utilities from "@unegma/utilities";
import { DeviceImageDataType } from "../types/DeviceImageDataType";

type NFTProps = {
  deviceImageData: DeviceImageDataType;
  gallery: any[];
}

export const NFT = ({ deviceImageData, gallery }: NFTProps) => {
  let nftPath = "";

  // todo check how this works with the undefined check block below now (for handling when image is deleted but cookie still set)
  if (deviceImageData.path.includes('https://')) {
    // is coming from URL
    nftPath = deviceImageData.path;
  } else {
    // else is coming from gallery
    let nftPathDotPath = utilities.getAttValue(gallery, deviceImageData.name, 'name');
    nftPath = `/gallery/${nftPathDotPath.path}`;
  } // could add a throw error here


  // const [useVideoDefault, setUseVideoDefault] = useState(true);

  // this is to handle when image is deleted but cookie is still set
  if (typeof nftPath === "undefined") {
    const lsImageData = localStorage.getItem("deviceImageData");
    if (typeof lsImageData !== "undefined" && lsImageData !== null && lsImageData !== "") {
      localStorage.setItem("deviceImageData", ""); // clear cookie
    }
  }

  const handleRef = (video: any) => {
    if (video !== null) {

      // this should check that deviceImageData.start and .end are set (including checking not "")
      if (typeof deviceImageData.start !== undefined && deviceImageData.start != null &&
        typeof deviceImageData.end !== undefined && deviceImageData.end != null) {

        // setUseVideoDefault(false);

        // todo implement for url based nft
        const startTime = Number(deviceImageData.start);
        console.log(`Start:  ${startTime}`);
        const endTime = Number(deviceImageData.end);
        console.log(`End:  ${endTime}`);

        video.addEventListener('timeupdate', () => {
          console.log(video.currentTime);
          //   // this solves the blinking screen issue
          if (video.currentTime >= endTime) {
            video.currentTime = startTime;
          }
          video.play();
        });

      }

    }
  }

  let rotationClasses = "nft";
  switch (deviceImageData.rotation) {
    case 0:
      rotationClasses = `${rotationClasses} nft--0`
      break;
    case 90:
      rotationClasses = `${rotationClasses} nft--90`
      break;
    case 180:
      rotationClasses = `${rotationClasses} nft--180`
      break;
    case 270:
      rotationClasses = `${rotationClasses} nft--270`
      break;
  }

  return (
    <div className="nft-container">
      {deviceImageData.extension === 'mp4' ?

        <video
          ref={handleRef}
          className={rotationClasses}
          key={deviceImageData.name} id="my-video" muted autoPlay loop>
          <source src={nftPath} type='video/mp4'/>
          <p className="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that
            <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
          </p>
        </video> :

        <img
          className={rotationClasses}
          src={nftPath}
        />
      }

    </div>
  )
}
