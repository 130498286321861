import './scss/app.scss';
import React, {useEffect} from 'react';
import { v4 as uuid } from 'uuid';
import { Settings } from "./components/Settings";
import { NFT } from "./components/NFT";
// import { DBUtilities } from '@unegma/aws-utilities';
// let dbUtilities = new DBUtilities('eu-west-2');
import * as utilities from "@unegma/utilities";
import gallery from "./helpers/gallery";
import { InfoBox } from "./components/InfoBox";
import {DeviceImageDataType} from "./types/DeviceImageDataType";

function App() {
  const [deviceId, setDeviceId] = React.useState<string>("");
  const [deviceImageData, setDeviceImageData] = React.useState<DeviceImageDataType>({id: 0, name: "", extension: "", path: "", rotation: 0});

  console.log(deviceImageData);

  useEffect(() => {
    // todo if delete a picture from the server, and the cookie is set, then it doesn't load the 'clear' option so have to manually clear cookies
    // TODO MAY NOT BE LOADING THE COOKIS PROPERLY BECAUSE NEEDS AWAIT ?

    let search = window.location.search;
    let queryString = new URLSearchParams(search);
    let user = queryString.get('user');
    let imageId = queryString.get('imageId');
    console.log(`Image Id: ${imageId}`);

    let iData;
    if (imageId) {
      let iData = utilities.getAttValue(gallery, parseInt(imageId), 'id');
      if (iData) {
        setDeviceImageData(iData);
        localStorage.setItem('deviceImageData', JSON.stringify(iData));
      }
    } else {
      iData = localStorage.getItem("deviceImageData");
      if (iData) {
        setDeviceImageData(JSON.parse(iData));
      }
    }


    let dId;
    if (user) {
      dId = user;
      localStorage.setItem('deviceId', user);
    } else {
      dId = localStorage.getItem("deviceId");
    }

    if (typeof dId === "undefined" || dId === null || dId === "") {
      let newDeviceId = uuid();
      setDeviceId(newDeviceId);
      localStorage.setItem('deviceId', newDeviceId);
      // todo add to dynamoDB
    } else {
      setDeviceId(dId);
    }
  }, []);

  return (
    <div className="App">

      <div>
        {deviceImageData.name !== "" || deviceImageData.path !== "" ?
          <div>
            <InfoBox type="floating" deviceIdProps={deviceId} />
            <NFT deviceImageData={deviceImageData} gallery={gallery} />
          </div> :
          <div>
            <InfoBox type="fixed" deviceIdProps={deviceId} />
            <Settings gallery={gallery} />
          </div>
        }
      </div>

    </div>
  );
}

export default App;
