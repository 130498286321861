import '../scss/settings.scss';
import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import SaveIcon from "@material-ui/icons/Save";
import TableRow from '@material-ui/core/TableRow';
import {
  Button, createStyles, makeStyles, MenuItem, Select, Switch, TextField, Theme, Typography
} from "@material-ui/core";
import * as utilities from '@unegma/utilities';
// todo jwt, assign own ids to tvs and sign in with pseudo ID/email
import { DeviceImageDataType } from "../types/DeviceImageDataType";
import Modal from '@material-ui/core/Modal';
import { NFT } from "./NFT";
import MoreSettings from "./MoreSettings";
import SettingsOptions from "./SettingsOptions";

type SettingsProps = {
  gallery: any[];
}


// todo clear cookies on error?



// better to use this here (than css) so faded background works properly
const modalStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);


export function Settings({gallery}: SettingsProps) {
  const [rotation, setRotation] = React.useState<number>(0);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [moreSettingsOpen, setMoreSettingsOpen] = React.useState<boolean>(true);
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = React.useState<boolean>(false);
  const [nftAsUrl, setNftAsUrl] = React.useState<string>("https://content.knownorigin.io/network/mainnet/edition/556800/full.mp4");
  const [modalImage, setModalImage] = React.useState<DeviceImageDataType>({name: "", path: "", extension: "", rotation: 0});
  const classes2 = modalStyles();

  const updateNftAsUrl = (url: string) => {
    setNftAsUrl(url);
  }

  const handleOpenModal = (image: any) => {
    setModalOpen(true);
    setModalImage(image);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const updateVideoTime = (value: any, image: any, key: string) => {
    console.log(value);
    if (key === 'start') {
      image.start = value;
    } else if (key === 'end') {
      image.end = value;
    }
  }

  // const updateVideoTimeUrl = (value: any, image: any, key: string) => {
  //   console.log(value);
  //   if (key === 'start') {
  //     image.start = value;
  //   } else if (key === 'end') {
  //     image.end = value;
  //   }
  // }

  useEffect(() => {
    const lsAdvancedSettings = localStorage.getItem("showAdvancedSettings");
    if (lsAdvancedSettings === "true") {
      setAdvancedSettingsOpen(true);
    }
    const lsMoreSettings = localStorage.getItem("showMoreSettings");
    if (lsMoreSettings === "true") {
      setMoreSettingsOpen(true);
    } else if (lsMoreSettings === "false") {
      setMoreSettingsOpen(false); // needs to be set here too because default is true
    }

  }, [advancedSettingsOpen, setAdvancedSettingsOpen]);

  const toggleMoreSettings = () => {
    setMoreSettingsOpen(!moreSettingsOpen);
    localStorage.setItem('showMoreSettings', JSON.stringify(!moreSettingsOpen));
  }
  const toggleAdvancedSettings = () => {
    setAdvancedSettingsOpen(!advancedSettingsOpen);
    localStorage.setItem('showAdvancedSettings', JSON.stringify(!advancedSettingsOpen));
  }

  const updateRotation = (rot: any) => {
    setRotation(rot);
  }

  const saveSettings = (image: DeviceImageDataType) => {
    const imageData = utilities.getAttValue(gallery, image.name, 'name');
    let deviceData = { ...imageData };
    localStorage.setItem('deviceImageData', JSON.stringify(deviceData));
    location.reload();
  };

  const saveSettingsUrl = (image: DeviceImageDataType) => {

    // todo make this work automatically
    let nftUrl = image.path;
    let extension;
    if (nftUrl.includes('.gif')) {
      extension = 'gif';
    } else if (nftUrl.includes('.png')) {
      extension = 'png';
    } else if (nftUrl.includes('.svg')) {
      extension = 'svg';
    } else if (nftUrl.includes('.jpg')) {
      extension = 'jpg';
    } else if (nftUrl.includes('.jpeg')) {
      extension = 'jpeg';
    } else if (nftUrl.includes('.mp4')) {
      extension = 'mp4';
    }

    // console.log(image);
    // console.log(`Gallery:`, gallery);
    let rot;

    // set rotation to user set rotation if there is no rotation on the object
    if (image.rotation) {
      rot = image.rotation;
      // // setRotation(image.rotation); // todo not working and not sure why
      // // hacky fix for the above
      if (typeof image.rotation === "string") {
        rot = parseInt(image.rotation);
      } else {
        rot = image.rotation;
      }
    } else {
      rot = 0;
    }

    // orientation wil map to orientation
    let deviceData = { path: nftUrl, name: "", extension: extension, rotation: rot };
    localStorage.setItem('deviceImageData', JSON.stringify(deviceData));
    location.reload();
  };

  return (
    <div className="settings-page">

      <Modal
        className="image-modal"
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes2.paper}>
          <h2 id="simple-modal-title">{modalImage.name}</h2>
          <NFT deviceImageData={modalImage} gallery={gallery} />
        </div>
      </Modal>

      <SettingsOptions
        moreSettingsOpen={moreSettingsOpen}
        toggleMoreSettings={toggleMoreSettings}
        advancedSettingsOpen={advancedSettingsOpen}
        toggleAdvancedSettings={toggleAdvancedSettings}
      />

      <div>

        {/*info about how to run on different devices and what you should do etc*/}
        {/*info about running on mobile (let it load)*/}
        {/*info about if there are black frames at the end (setting the time arbitrarily) (blue butterfly one)*/}
        {/*get in touch if you need custom hosting solutions with uploaded images or needing them trimmed*/}

        {/*this software works best if the video being downloaded isn't more than..*/}

        {/*hover in the bottom left corner to bring back the settings menu*/}

        {/* todo show advanced settings checkbox, and set in cookies too*/}

        {/*arbitrary rotation in settings/*/}

        {/*todo info about tv requirements*/}

        {/*my saved urls*/}

        {/*show type automatically next to url*/}
        {/*add donate button*/}

        {/*pull down nft list from the graph*/}

      </div>

      <TableContainer >
        <Table className="" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><p><b>Image</b></p></TableCell>

              {moreSettingsOpen && (
                <>
                  <TableCell><p><b>Rotation</b></p></TableCell>
                  {/*<TableCell><p><b>Audio</b></p></TableCell>*/}
                  {advancedSettingsOpen && (
                    <>
                      <TableCell><p><b>Loop Start<br/>(Optional for mp4s)</b></p></TableCell>
                      <TableCell><p><b>Loop End<br/>(Optional for mp4s)</b></p></TableCell>
                    </>
                  )}
                </>
              )}
              <TableCell><p><b>Save & Set Image</b></p></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {/*As URL Input*/}

            {/*<TableRow key="urlKey" style={{background: "#eee"}}>*/}
            {/*  <TableCell>*/}
            {/*    <TextField*/}
            {/*      className="urlInput"*/}
            {/*      type="url"*/}
            {/*      value={nftAsUrl}*/}
            {/*      onChange={(e) => updateNftAsUrl(e.target.value)}*/}
            {/*      label="NFT Url"*/}
            {/*    />*/}
            {/*  </TableCell>*/}

            {/*  {moreSettingsOpen && (*/}
            {/*    <MoreSettings*/}
            {/*      rotation={rotation}*/}
            {/*      updateRotation={updateRotation}*/}
            {/*      advancedSettingsOpen={advancedSettingsOpen}*/}
            {/*    />*/}
            {/*  )}*/}

            {/*  <TableCell>*/}
            {/*    <Button*/}
            {/*      variant="contained"*/}
            {/*      color="primary"*/}
            {/*      size="small"*/}
            {/*      onClick={() => saveSettingsUrl({name: '', extension: '', rotation: rotation, path: nftAsUrl})}*/}
            {/*    >*/}
            {/*      <SaveIcon/>&nbsp;Set Image*/}
            {/*    </Button>*/}
            {/*  </TableCell>*/}
            {/*</TableRow>*/}

            {/*As Gallery*/}

            {gallery && gallery.length ?
              gallery.map((image: any, index: number) => (
                <TableRow key={index} style={ index % 2 ? { background : "white" }:{ background : "#eee" }}>
                  <TableCell>
                    <a className="image-name" href="#"
                      onClick={() => handleOpenModal(image)}
                    >
                      {image.name}
                    </a>
                  </TableCell>

                  {moreSettingsOpen && (
                    <MoreSettings
                      image={image}
                      rotation={image.rotation}
                      updateRotation={updateRotation}
                      advancedSettingsOpen={advancedSettingsOpen}
                    />
                  )}

                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => saveSettingsUrl(image)}
                    >
                      <SaveIcon/>&nbsp;Set Image
                    </Button>
                  </TableCell>

                </TableRow>
              )) :
              ""
            }
          </TableBody>
        </Table>
      </TableContainer>
      <br/><br/>
      <Typography><a style={{float:"right", color:"black", marginRight: "2px"}} target="_blank" href="https://unegma.com">unegma.com</a></Typography>

    </div>
  )
}
