import React from 'react';
import {Switch, Typography} from "@material-ui/core";

export default function SettingsOptions({moreSettingsOpen, toggleMoreSettings, advancedSettingsOpen, toggleAdvancedSettings}:any) {

  return (
    <>
      <h1>
        Settings
      </h1>

      <div className="moreSettingsBox">
        <Typography>Show More Settings:</Typography>
        <Switch
          checked={moreSettingsOpen}
          onChange={toggleMoreSettings}
          name="moreSettings"
          inputProps={{ 'aria-label': 'more settings' }}
        />
      </div>

      {moreSettingsOpen && (
        <div className="advancedSettingsBox">
          <Typography>Show Advanced Settings:</Typography>
          <Switch
            checked={advancedSettingsOpen}
            onChange={toggleAdvancedSettings}
            name="advancedSettings"
            inputProps={{ 'aria-label': 'advanced settings' }}
          />
        </div>
      )}
    </>
  )
}
