import {DeviceImageDataType} from "../types/DeviceImageDataType";

// export default (() => { // todo research exporting like this and importing: import "./helpers/gallery"; (for auto running code)
let gallery: DeviceImageDataType[];
const importAll = (r: any) => {
  return r.keys().map(r);
}

gallery = [
  {
    id: 1,
    path: "https://content.knownorigin.io/network/mainnet/edition/818000/full.mp4",
    name: "Test",
    extension: "mp4",
    rotation: 270,
  }, {
    id: 2,
    path: "https://ipfs.infura.io/ipfs/QmYRXELpbCYm1WmxnmPt6vZ1XCGnAAuicQ81s3JdGAbFX9/asset.mp4",
    name: "Embracing Chaos",
    extension: "mp4",
    rotation: 0,
  }, {
    id: 3,
    path: "https://content.knownorigin.io/network/mainnet/edition/383350/asset.png",
    name: "Bloom",
    extension: "png",
    rotation: 270,
  }, {
    id: 4,
    path: "https://content.knownorigin.io/network/mainnet/edition/359300/asset.png",
    name: "Emzor S Blue",
    extension: "png",
    rotation: 270,
  }, {
    id: 5,
    path: "https://content.knownorigin.io/network/mainnet/edition/105500/asset.gif",
    name: "105523",
    extension: "gif",
    rotation: 270,
  }
];

// for pulling from folder
// gallery = importAll(require.context('../../public/gallery', false, /\.(mp4|png|jpe?g)$/)); // importing from folder or url
// gallery = gallery.map((image: any) => {
//   let imageObject: DeviceImageDataType = {path: "", name: "", extension: "", rotation: 0};
//   let filename = image.default.split("/")[3];
//   imageObject.name = filename.split(".")[0];
//   let extension = filename.split(".")[2];
//   imageObject.path = `${imageObject.name}.${extension}`;
//   imageObject.extension = extension;
//   return imageObject;
// });
// })

export default gallery;
