import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import {MenuItem, Select} from "@material-ui/core";
import AdvancedSettings from "./AdvancedSettings";

export default function MoreSettings({image, rotation, updateRotation, advancedSettingsOpen}: any) {

  return (
    <>
      <TableCell>
        <Select
          value={rotation}
          onChange={(ev) => {image.rotation = ev.target.value; updateRotation(ev.target.value)}}
        >
          {/*todo why can't set 0 unless changing to another option first???*/}
          <MenuItem key={0} value={0}>0Deg</MenuItem>
          <MenuItem key={1} value={90}>90Deg</MenuItem>
          <MenuItem key={2} value={180}>180Deg</MenuItem>
          <MenuItem key={3} value={270}>270Deg</MenuItem>
        </Select>
      </TableCell>
      {advancedSettingsOpen && (
        <AdvancedSettings></AdvancedSettings>
      )}
    </>
  )
}
