import '../scss/infobox.scss';
import {Button, Typography} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, {useEffect} from "react";
import {Fullscreen} from "@material-ui/icons";

type InfoBoxProps = {
  type: string,
  deviceIdProps: string
}

export const InfoBox = ({type, deviceIdProps}: InfoBoxProps) => {
  const [showClear, setShowClear] = React.useState(0);
  const [deviceId, setDeviceId] = React.useState("");
  const [hideBox, setHideBox] = React.useState(false);

  useEffect( () => {
    setDeviceId(deviceIdProps);
  }, [deviceIdProps]);

  const handleShowClick = () => {
    setShowClear(showClear + 1);
  };

  /**
   * This isn't updated properly visually when running from settings page, but still works. may need to lift up
   */
  const clearDeviceId = () => {
    localStorage.setItem('deviceId', "");
    location.reload();
  };

  const clearSettings = () => {
    localStorage.setItem('deviceImageData', "");

    let search = window.location.search;
    let queryString = new URLSearchParams(search);
    let user = queryString.get('user');
    let imageId = queryString.get('imageId');

    // remove query string parameters if clearing settings
    if (user || imageId) {
      console.log('here')
      location.href = location.origin + location.pathname;
    } else {
      location.reload();
    }

  };

  const toggleHideBox = () => {
    setHideBox(!hideBox);
  };

  const fullScreen = () => {
    let element = document.body; // Make the body go full screen.
    // Supports most browsers and their versions.
    // var requestMethod = element.requestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullscreen || element.msRequestFullscreen;
    let requestMethod = element.requestFullscreen;

    if (requestMethod) { // Native full screen.
      requestMethod.call(element);
    }
  }

  return (
    <div
      className={type === 'floating' ? (hideBox ? 'info-box--floating info-box--floating__hide' : 'info-box--floating') : 'info-box--fixed'}
      onMouseEnter={() => {if(hideBox) {toggleHideBox()}}}
      onClick={() => {if(hideBox) {toggleHideBox()}}}
    >
      <p className="center" onClick={handleShowClick}>Hello {deviceId}!</p>
      {showClear >= 5 ? <p>
        <button onClick={clearDeviceId}>Renew ID</button>
      </p> : null}
      {/*<span>(Hidden automatically when image showing, hover bottom left to reveal).</span>*/}
      <p className="center">

        {type === 'floating' ?
          <>
            <HighlightOffIcon className="closeModalButton" onClick={toggleHideBox}/>

            <Typography>Move cursor to bottom left to re-reveal this info box.</Typography>
            <br/>
            <Button
              className="fullscreen-button"
              variant="contained"
              color="primary"
              size="small"
              onClick={fullScreen}
              startIcon={<Fullscreen/>}
            >
              Make FullScreen<br/>(desktop only)
            </Button>
            <br/><br/>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={clearSettings}
              startIcon={<SaveIcon/>}
            >
              Clear Settings
            </Button>
            <br/><br/>
            <Typography><a style={{float:"right", color:"black"}} target="_blank" href="https://unegma.com">unegma.com</a></Typography>
          </>
          : ''
        }
      </p>
    </div>
  )
}
